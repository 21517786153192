// Contains the JS stuff that we need for completion and saved-time



$(() => {
    // Are there any divs of class .now_clock?
    // Show time and update each second
    // (https://stackoverflow.com/a/28059618/278842)
    if (document.querySelector('.now_clock') !== null) {

        function setSecondInterval(callback) {
            setTimeout(function() {
                callback();
                setSecondInterval(callback);
            }, 1000 - Date.now() % 1000);
        }

        setSecondInterval(() => {
            $('.now_clock').text(new Date().toLocaleTimeString());
            $('.now_clock').attr('placeholder', new Date().toLocaleTimeString());
        });
    }

    // Are there any buttons of type data-now?
    // Clicking them will set the associated input
    if (document.querySelector('input[data-now]') !== null) {

        function now_local() {
            var now = new Date();
            return new Date(now.getTime() - now.getTimezoneOffset() * 60000).toISOString().substring(0, 16);
        }

        $('input[data-now]').click((e) => {
            $($(e.currentTarget).data("now")).val(now_local());
        });

    }
});