const mediaQuery = window.matchMedia('(min-width: 769px)')
 
function handleTabletChange(e) {
  const kdigo_links = document.getElementsByClassName('kdigo2024_img_link');
  if (kdigo_links != null) {
    for (let i = 0; i < kdigo_links.length; i++) {
      if (e.matches) {
        kdigo_links[i].href = 'https://www.kidney-international.org/cms/attachment/0f7b23c0-e846-499a-8309-64f5d4de1912/fx2_lrg.jpg'
      } else {
        kdigo_links[i].href = 'https://www.kidney-international.org/cms/attachment/859cac95-0534-4aa3-b766-7f5e72700f6c/fx2.jpg'
      }
    }
  }
  return false;
}
 
mediaQuery.addEventListener('change', handleTabletChange);

document.addEventListener('DOMContentLoaded', function() {
  handleTabletChange(mediaQuery);
});

var ready = function() {

    $('.card_more_button').on('click',
        function() {
            $(this).closest('.card').toggleClass('card_full');
            $(this).siblings('.card_more_content').slideToggle('fast');
            $(this).toggleClass('flipY');
        })

};

$(document).ready(ready);
$(document).on("page:load", ready);
